import React from 'react'
// import Swiper core and required modules
import { Pagination } from 'swiper'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import client1 from '../../assets/avatar1.jpg'
import client2 from '../../assets/avatar2.jpg'
import client3 from '../../assets/avatar3.jpg'
import client4 from '../../assets/avatar4.jpg'
import './testimonials.css'




const data = [
  {
    id:1,
    image:client1,
    name:'Robin Hood',
    review:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ex pariatur corrupti necessitatibus distinctio autem, animi magnam qui, quo accusantium expedita quia iusto deleniti eius provident consectetur? Voluptas unde repudiandae corporis reiciendis facilis? Excepturi dignissimos ipsam eaque fugit facere laboriosam possimus sapiente consequuntur tempora numquam adipisci deleniti ratione minima, rem magni."
  },
  {
    id:2,
    image:client2,
    name:'Robin Hood',
    review:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ex pariatur corrupti necessitatibus distinctio autem, animi magnam qui, quo accusantium expedita quia iusto deleniti eius provident consectetur? Voluptas unde repudiandae corporis reiciendis facilis? Excepturi dignissimos ipsam eaque fugit facere laboriosam possimus sapiente consequuntur tempora numquam adipisci deleniti ratione minima, rem magni."
  },
  {
    id:3,
    image:client3,
    name:'Robin Hood',
    review:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ex pariatur corrupti necessitatibus distinctio autem, animi magnam qui, quo accusantium expedita quia iusto deleniti eius provident consectetur? Voluptas unde repudiandae corporis reiciendis facilis? Excepturi dignissimos ipsam eaque fugit facere laboriosam possimus sapiente consequuntur tempora numquam adipisci deleniti ratione minima, rem magni."
  },
  {
    id:4,
    image:client4,
    name:'Robin Hood',
    review:"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ex pariatur corrupti necessitatibus distinctio autem, animi magnam qui, quo accusantium expedita quia iusto deleniti eius provident consectetur? Voluptas unde repudiandae corporis reiciendis facilis? Excepturi dignissimos ipsam eaque fugit facere laboriosam possimus sapiente consequuntur tempora numquam adipisci deleniti ratione minima, rem magni."
  },
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
        <h5 className='testimonials__head'>Review from client</h5>
        <h2>Testimonials</h2>

        <Swiper className='container testimonials__container' 
          // install Swiper modules
        modules={[ Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
        >
          {
            data.map(({id,image, name, review}, index) => {
              return(
                <SwiperSlide key={id} className='testimonial'>
                  <div className='client__avatar'>
                    <img src={image} alt="avatar1" />
                  </div>
                  <h5 className='client__name'>{name}</h5>
                  <small className='client_review'>{review}</small>
                </SwiperSlide>
              )
            })
          }
        </Swiper>
    </section>
  )
}

export default Testimonials