import React from 'react'
import ME from '../../assets/me2.png'
import CTA from './CTA'
import './header.css'
import HeaderSocial from './HeaderSocial'

const Header = () => {
  return (
    <header>
      <div className='container header_container'>
        <h5>Hello I'm</h5>
        <h1>Ashraful Haque Prohor</h1>
        <h5 className='text-light'>Fullstack Developer</h5>
        <div>
        <CTA></CTA>
        </div>
        <div><HeaderSocial></HeaderSocial></div>
        
        
        <div className='me'>
          <img src={ME} alt="prohorimage" />
        </div>
        
        <a href="#contact" className='scroll_down'>Scrol Down</a>
      </div>
    </header>
  )
}

export default Header