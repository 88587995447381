import React from 'react'
import img1 from '../../assets/portfolio1.jpg'
import img2 from '../../assets/portfolio2.jpg'
import img3 from '../../assets/portfolio3.jpg'
import img4 from '../../assets/portfolio4.jpg'
import img5 from '../../assets/portfolio5.png'
import img6 from '../../assets/portfolio6.jpg'
import './portfolio.css'

const data = [
  {
    id:1,
    image:img1,
    title: 'A shorthand property for setting the four transition properties into a single property',
    github:'https://github.com/AHProhor',
    demo:'https://dribble.com'
  },
  {
    id:2,
    image:img2,
    title: 'A shorthand property for setting the four transition properties into a single property',
    github:'https://github.com/AHProhor',
    demo:'https://dribble.com'
  },
  {
    id:3,
    image:img3,
    title: 'A shorthand property for setting the four transition properties into a single property',
    github:'https://github.com/AHProhor',
    demo:'https://dribble.com'
  },
  {
    id:4,
    image:img4,
    title: 'A shorthand property for setting the four transition properties into a single property',
    github:'https://github.com/AHProhor',
    demo:'https://dribble.com'
  },
  {
    id:5,
    image:img5,
    title: 'A shorthand property for setting the four transition properties into a single property',
    github:'https://github.com/AHProhor',
    demo:'https://dribble.com'
  },
  {
    id:6,
    image:img6,
    title: 'A shorthand property for setting the four transition properties into a single property',
    github:'https://github.com/AHProhor',
    demo:'https://dribble.com'
  }
]
const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5 className='portfolio__class'>MY Recent Work </h5>
      <h2>Portfolio</h2>
      <div className='container portfolio__container'>
         {
           data.map(({id,image,title,github,demo})=>{
             return(
              <article  key={id} className='portfolio__item'>
              <div className='portfolio_item-image'>
                <img src={image} alt={title} />
              </div>
              <h4>{title}</h4>
              <div className='portfolio__item-cta'>
                <a href={github} className='btn' target='blank'> Github </a>
                <a href={demo} className='btn' target='blank'> Live Demo </a>
              </div>
            </article> 
             )
           })
         }
      </div>
    </section>
  )
}

export default Portfolio