import React, { useState } from 'react';
import { AiOutlineUser, AiTwotoneHome } from 'react-icons/ai';
import { BiBook, BiMessageDetail } from 'react-icons/bi';
import { RiServiceLine } from 'react-icons/ri';
import './nav.css';

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#');
  return (
    <nav>
      <a href="#" onClick={()=> setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}> <AiTwotoneHome></AiTwotoneHome> </a>
      <a href="#about" onClick={()=> setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''} ><AiOutlineUser></AiOutlineUser></a>
      <a href="#experience" onClick={()=> setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}><BiBook></BiBook></a>
      <a href="#service" onClick={()=> setActiveNav('#service')} className={activeNav === '#service' ? 'active' : ''}><RiServiceLine></RiServiceLine></a>
      <a href="#contact" onClick={()=> setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><BiMessageDetail></BiMessageDetail></a>
    </nav>
  )
}

export default Nav