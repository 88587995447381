import React from 'react';
import { BsDribbble, BsGithub, BsLinkedin } from 'react-icons/bs';

const HeaderSocial = () => {
  return (
    <div className='header__social'>
        <a href="https://www.linkedin.com/in/ashraful-haque-prohor-220151159/ " target="_blank" rel="noreferrer" ><BsLinkedin></BsLinkedin></a>
        <a href="https://github.com/AHProhor" target="_blank" rel="noreferrer" ><BsGithub></BsGithub></a>
        <a href="https://dribble.com " target="_blank" rel="noreferrer" ><BsDribbble></BsDribbble></a>
    </div>
  )
}

export default HeaderSocial