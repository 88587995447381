import React from 'react';
import { FaAward } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { VscFolderActive } from 'react-icons/vsc';
import about from '../../assets/Prhr1000.png';
import './about.css';
const About = () => {
  return (
    <section id='about'>
      <h5 className='about__title'>Get To Know</h5>
      <h2>About Me</h2>
      <div className='container about__container'>
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={about} alt="" />
          </div>
        </div>
        
        <div className='about__content'>
          <div className='about__cards'>
            <article className='about__card'>
              <FaAward className='about__icon'></FaAward>
              <h5>Experience</h5>
              <small>3+ Years Working</small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon'></FiUsers>
              <h5>Client</h5>
              <small>200+ Worldwide</small>
            </article>

            <article className='about__card'>
              <VscFolderActive className='about__icon'></VscFolderActive>
              <h5>Projects</h5>
              <small>80+ Completed</small>
            </article>
          </div>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellat qui fugit accusantium consectetur magnam rerum, amet veritatis ipsam iure cum. Iusto necessitatibus eveniet illo quaerat quod veniam voluptas et aliquid?
          </p>
          <a href="#contact" className='btn btn-primary'> Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About