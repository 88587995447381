import React from 'react'
import { BiCheck } from 'react-icons/bi'
import './services.css'
const Services = () => {
  return (
    <section id='service'>
      <h5 className='service__title'>What I Offer</h5>
      <h2>Services</h2>
      <div className='container services__container'>
        <article className='service'>
          <div className='service__head'>
            <h3>UI/UX Design</h3>
          </div>
            <ul className='service__list'>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
            </ul>
        </article>
        {/* End of UI/UX design */}
        <article className='service'>
          <div className='service__head'>
            <h3>Web Development</h3>
          </div>
            <ul className='service__list'>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
            </ul>
        </article>
        {/* end of webDevelopment */}
        <article className='service'>
          <div className='service__head'>
            <h3>Content Creation</h3>
          </div>
            <ul className='service__list'>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon'></BiCheck>
                <p>Lorem ipsum dolor sit amet.</p>
              </li>
            </ul>
        </article>
        {/* End of content creation */}
      </div>
    </section>
  )
}

export default Services