import emailjs from 'emailjs-com';
import React, { useRef } from 'react';
import { BsMessenger, BsWhatsapp } from 'react-icons/bs';
import { MdOutlineMail } from 'react-icons/md';
import './contact.css';
const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_4ihmrcr', 'template_4z8b1fv', form.current, 'X-HURnOHzMsQaN3wI')
      e.target.reset();
  };

  return (
    <section id='contact'>
      <h5 className='contact__header'>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className='container contact__container'>
        <div className='contact__options'>
          <article className='contact__option'>
            <MdOutlineMail className='contact__option-icon'></MdOutlineMail>
            <h4>Email</h4>
            <h5>ahprohor4@gmail.com</h5>
            <a href="mailto:ahprohor4@gmail.com">Send a mail</a>
          </article>
          <article className='contact__option'>
            <BsMessenger className='contact__option-icon'></BsMessenger>
            <h4>Messenger</h4>
            <h5>Ashraful Haque Prohor</h5>
            <a href="https://m.me/profile.php?id=100009207907868" target="_blank" rel="noreferrer">Chat</a>
          </article>
          <article className='contact__option'>
            <BsWhatsapp className='contact__option-icon'></BsWhatsapp>
            <h4>Email</h4>
            <h5>+880-1689364853</h5>
            <a href="http://wa.me/8801689364853" target="_blank" rel="noreferrer">Chat</a>
          </article>
        </div>
        {/* End of contact options */}

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required/>
          <input type="email" name='email' placeholder='Your Email' required/>
          <textarea name="message" id="" rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact